import { Link } from "gatsby";
import React from "react";
import styles from "./PortfolioNav.module.css";

const PortfolioNav = props => (
  <div className={styles.sections}>
    {props.sections.map((section, key) => (
      <Link
        // activeClassName={styles.active}
        className={styles.section}
        key={key}
        to={`/portfolio/${section.slug}/`}
      >
        {section.title}
      </Link>
    ))}
  </div>
);

export default PortfolioNav;
