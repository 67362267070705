import { Link } from "gatsby";
import Img from "gatsby-image";
import React from "react";
import styles from "./PortfolioProject.module.css";

const PortfolioProject = props => (
  <Link className={styles.el} to={`/portfolio/${props.slug}/`}>
    <Img className={styles.image} fluid={props.thumbnailImage.fluid} />
    <h3 className={styles.title}>{props.title}</h3>
  </Link>
);

export default PortfolioProject;
