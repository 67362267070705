import React from "react";
import Divider from "../Divider";
import styles from "./index.module.css";
import PortfolioBody from "./PortfolioBody";
import PortfolioNav from "./PortfolioNav";
import PortfolioProject from "./PortfolioProject";

const Portfolio = props => (
  <div className={styles.el}>
    <div className={styles.container}>
      <Divider />
      <PortfolioNav sections={props.sections} />
      <Divider />
      <div className={styles.projects}>
        {props.projects.map((project, key) => (
          <PortfolioProject key={key} {...project} />
        ))}
      </div>
      {props.body && (
        <>
          <Divider />
          <PortfolioBody body={props.body} />
        </>
      )}
    </div>
  </div>
);

export default Portfolio;
